import {
    Autocomplete,
    Box,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    createFilterOptions,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    FormControlLabel,
    Checkbox,
    FormGroup,
    Stack,
    Button
  } from "@mui/material";
import { PersonPinOutlined,
    DragIndicatorTwoTone, 
  } from "@mui/icons-material";
import { useForm } from "../hooks";
import React, { useContext, useEffect } from "react";
import { AuthContext, PreventiviNewContext, NationsContext } from "../context";
import { isRequired } from "../hooks/useForm";
import * as R from "ramda";
import {
  Assicurazione,
  MerceADR,
  Contrassegno,
  DettaglioMisureMerce,
} from "../components/PreventiviFormComponent/"
import { useNavigate } from "react-router-dom";
import { titlePage, getFlagPath } from "../utilities";
import { green } from '@mui/material/colors';
import { QuestionAnswerTwoTone } from '@mui/icons-material'
import { DatePicker } from '@mui/x-date-pickers';
//import { TimePicker } from "@mui/x-date-pickers";
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { DateRangeRounded } from '@mui/icons-material';
import moment from 'moment';




const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 100,
  });
  


const PreventiviNew = () => {

    //MENU NAZIONI
    const {        state: { nations, provincesFrom, loading },
        getNationsLists, svuotaCacheNations,
      } = useContext(NationsContext);


   
    //valid check
    const { addValidCheck } = useContext(AuthContext);

    //INDICATORI MITTENTE E DESTINATARIO
    const [checkedIndicatoriMittente, setCheckedIndicatoriMittente] = React.useState([]);
    const [checkedIndicatoriDestinatario, setCheckedIndicatoriDestinatario] = React.useState([]);

    
    const [loadingPreventivi, setLoadingPreventivi] = React.useState(false);

    //import da PreventiviNewContext
    const { 
        state:{
            configPreventivo,
            indicatoriMitt,
            indicatoriDest,
            form
            },
        getIndicatoriMitt,
        getIndicatoriDest,
        postNuovoPreventivo,
        getConfigModePreventivo,
        resetPreventivo

     } = useContext(PreventiviNewContext);

     const navigate = useNavigate()

    //TODO cambiare nomi variabili
    const validations = [
        ({ MittLocalita }) =>
          isRequired(MittLocalita) || { MittLocalita: "*Località mittente obbligatoria" },
        ({ MittProvincia }) =>
          isRequired(MittProvincia) || { MittProvincia: "*Provincia mittente obbligatoria" },
        ({ MittNazione }) =>
          isRequired(MittNazione) || { MittNazione: "*Nazione mittente obbligatoria" },
        ({ MittCap }) => isRequired(MittCap) || { MittCap: "*Cap mittente obbligatorio" },
        ({ DestLocalita }) =>
          isRequired(DestLocalita) || { DestLocalita: "*Localita obbligatoria" },
        ({ DestProvincia }) =>
          isRequired(DestProvincia) || { DestProvincia: "*Localita obbligatoria" },
        ({ DestCap }) =>
          isRequired(DestCap) || { DestCap: "*Localita obbligatoria" },
        ({ DestNazione }) =>
          isRequired(DestNazione) || { DestNazione: "*Localita obbligatoria" },
      ];


    //FORM
    const {
        values: nuovoPreventivoForm,
        changeHandler,
        touched,
        errors,
        isValid,
        setValues
      } = useForm(
        {
          //mittente
          MittLocalita: "",
          MittProvincia: "",
          MittCap: "",
          MittNazione: "IT",
          MittNazioneCompleta : "Italia",
          IndicatoriMitt : [],

          //destinatario
          DestLocalita: "",
          DestProvincia: "",
          DestCap: "",
          DestNazione: "IT",
          IndicatoriDest : [],
          DestNazioneCompleta : "Italia",

          ServizioPerWeb:" ",
          ordmerce:[],
          //contrassegno
          TipoIncasso: "",
          Contrassegno: "",
          ContrassegnoValuta: "EUR",

          //adr
          ordadr:[],

          //assicurazione
          StimaValMerce:"",
          StimaValMerceValuta:"EUR",
          totRows : {},
          Epal: 0,
          DataRichiesta: "",
          OraRichiesta : "",

        },
        validations
      );
      if (nuovoPreventivoForm.MittNazione === undefined){
        setValues({
        ...nuovoPreventivoForm,
         MittNazione : "IT"
        
        })
      }

      if (nuovoPreventivoForm.DestNazione === undefined){
        setValues({
        ...nuovoPreventivoForm,
         DestNazione : "IT",
        
        })
      }

      
      const resetPreventivoForm = () => {       
        window.location.reload()
      }

      const changeMittValue = (newValue) => {
        if(newValue){
          changeHandler("MittLocalita", newValue);
          nuovoPreventivoForm.MittCap = newValue.split(" - ")[0];
          nuovoPreventivoForm.MittProvincia = newValue.split(" - ")[2]
            ? newValue.split(" - ")[2]
            : "";
        }else{
          changeHandler("MittLocalita", newValue);
        }
      };


      //INDICATORI MERCE
      const changeIndicatoriMittenteChecked = (isChecked, code) => {
        if (isChecked) {
          setCheckedIndicatoriMittente([
            ...checkedIndicatoriMittente.filter((res) => res !== code),
            code,
          ]);
          setValues({
            ...nuovoPreventivoForm,
            IndicatoriMitt: [
              ...checkedIndicatoriMittente.filter((res) => res !== code),
              code,
            ],
          });
        } else {
          setCheckedIndicatoriMittente([
            ...checkedIndicatoriMittente.filter((res) => res !== code),
          ]);
          setValues({
            ...nuovoPreventivoForm,
            IndicatoriMitt: [...checkedIndicatoriMittente.filter((res) => res !== code)],
          });
        }
      };


      const changeIndicatoriDestinatarioChecked = (isChecked, code) => {
        if (isChecked) {
          setCheckedIndicatoriDestinatario([
            ...checkedIndicatoriDestinatario.filter((res) => res !== code),
            code,
          ]);
          setValues({
            ...nuovoPreventivoForm,
            IndicatoriDest : [
              ...checkedIndicatoriDestinatario.filter((res) => res !== code),
              code,
            ],
          });
        } else {
          setCheckedIndicatoriDestinatario([
            ...checkedIndicatoriDestinatario.filter((res) => res !== code),
          ]);
          setValues({
            ...nuovoPreventivoForm,
            IndicatoriDest: [...checkedIndicatoriDestinatario.filter((res) => res !== code)],
          });
        }
      };

      const changeDestValue = (newValue) => {
        if(newValue){
          changeHandler("DestLocalita", newValue);
          nuovoPreventivoForm.DestCap = newValue.split(" - ")[0];
          nuovoPreventivoForm.DestProvincia = newValue.split(" - ")[2]
            ? newValue.split(" - ")[2]
            : "";
        }else{
          changeHandler("DestLocalita", newValue);
        }
      };


      useEffect(() => {
        addValidCheck(isValid);
      }, [isValid]);
    
      useEffect(() => {
        //TODO gestire traduzioni titolo pagina
        titlePage("","Preventivi")
        getConfigModePreventivo();
        getIndicatoriMitt();
        getIndicatoriDest();
        //dett. merce 
   
        if (form.MittLocalita != '' && form.MittLocalita != undefined){
          //setValues(form)

          setValues({
            ...nuovoPreventivoForm,
            MittLocalita: form.MittLocalita,
            MittProvincia: form.MittProvincia,
            MittCap: form.MittCap,
            MittNazione: form.MittNazione,
            MittNazioneCompleta : form.MittNazioneCompleta,
            IndicatoriMitt : form.IndicatoriMitt,
  
            //destinatario
            DestLocalita:  form.DestLocalita,
            DestProvincia:  form.DestProvincia,
            DestCap:  form.DestCap,
            DestNazione:  form.DestNazione,
            IndicatoriDest :  form.IndicatoriDest,
            DestNazioneCompleta :  form.DestNazioneCompleta,
  
            ServizioPerWeb:form.ServizioPerWeb,
            ordmerce:form.ordmerce,
            //contrassegno
            TipoIncasso: form.TipoIncasso,
            Contrassegno: form.Contrassegno,
            ContrassegnoValuta: form.ContrassegnoValuta,
  
            //adr
            ordadr:form.ordadr,
  
            //assicurazione
            StimaValMerce:form.StimaValMerce,
            StimaValMerceValuta:form.StimaValMerceValuta,
            totRows : form.totRows,
            
            Epal: form.Epal,
            DataRichiesta: "",
            OraRichiesta : "",

            })
          setCheckedIndicatoriMittente(form.IndicatoriMitt)
          setCheckedIndicatoriDestinatario(form.IndicatoriDest)
        }        
        return function cleanup() {
          svuotaCacheNations();
        };

      }, []);


      // useEffect(() => {
      // }, [nuovoPreventivoForm]);

      localStorage.setItem("nations", JSON.stringify(nations))
    
      useEffect(() => {

        getNationsLists(nuovoPreventivoForm.MittNazione);


        if (nuovoPreventivoForm.MittNazione !== "IT") {
            nuovoPreventivoForm.MittCap = "";
            nuovoPreventivoForm.MittLocalita = "";
            nuovoPreventivoForm.MittProvincia = "";
        }

        nations.map((params, key) => {
            if (params.nazioneiso === nuovoPreventivoForm.MittNazione){
              nuovoPreventivoForm.MittNazioneCompleta = params.descrizione;
             
            };
        });

      }, [nuovoPreventivoForm.MittNazione]);

      useEffect(() => {
        getNationsLists(nuovoPreventivoForm.DestNazione);
        if (nuovoPreventivoForm.DestNazione !== "IT") {
            nuovoPreventivoForm.DestCap = "";
            nuovoPreventivoForm.DestLocalita = "";
            nuovoPreventivoForm.DestProvincia = "";
        }

        nations.map((params, key) => {
            if (params.nazioneiso === nuovoPreventivoForm.DestNazione){
              nuovoPreventivoForm.DestNazioneCompleta = params.descrizione;             
            };
        });

      }, [nuovoPreventivoForm.DestNazione]);

    

  return (
    <div>
      <Stack direction={"row-reverse"} spacing={2} mt={2}>
        <Box sx={{position:'relative'}}>
          <Button
            color="success"
            size={"medium"}
            variant={"contained"}
            disabled={loadingPreventivi}
            onClick={() => postNuovoPreventivo(nuovoPreventivoForm/*, handleClose*/, navigate, setLoadingPreventivi)}
            >
              calcola preventivo
          </Button>
          {loadingPreventivi && (
            <CircularProgress
              size={24}
              sx={{
                color: green[500],
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
        
        <Box sx={{position:'relative'}}>
          <Button
            color="warning"
            size={"medium"}
            variant={"contained"}
            disabled={loadingPreventivi}
            onClick={() => resetPreventivoForm()}
            >
              SVUOTA CAMPI
          </Button>
          {loadingPreventivi && (
            <CircularProgress
              size={24}
              sx={{
                color: green[500],
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      </Stack>
        
        <Card elevation={0} sx={{ background: (theme) => theme.palette.error.light + "25" }}>

        <CardHeader 
            title={"Mittente"}
            subheaderTypographyProps={{
              fontWeight: "bold",
            }}
            titleTypographyProps={{
              fontWeight: "bold",
              fontSize: 18,
            }}
            subheader={"Dati del Mittente"}
            avatar={<PersonPinOutlined />}
          />
          <CardContent>

            
            <Grid item container spacing={1}>
              <Grid item xs={12} md={6} lg={6} mt={2}>
                <FormControl fullWidth>
                  <InputLabel id="select-from-nations">Nazione</InputLabel>
                  <Select
                    fullWidth
                    disabled={loading}
                    size="small"
                    labelId="select-from-nations"
                    id="select-from-nations"
                    value={nuovoPreventivoForm.MittNazione || ""}
                    label="Nazione"
                    onChange={(e) => changeHandler("MittNazione", e.target.value)}
                  >
                    <MenuItem value={-1}>SELEZIONA NAZIONE</MenuItem>
                    {nations?.map((params, key) => (
                      
                      <MenuItem key={key} value={params.nazioneiso}>
                        <Box sx={{ "& > img": { mr: 2, flexShrink: 0 } }}>
                          <img
                            loading="lazy"
                            width="20"
                            src={
                              params.nazioneiso?.toLowerCase() !== ""
                                ? `${getFlagPath(window.location.href, params.nazioneiso)}`
                                : ""
                            }
                            srcSet={
                              params.nazioneiso?.toLowerCase() !== ""
                                ? `${getFlagPath(window.location.href, params.nazioneiso)} 2x`
                                : ""
                            }
                            alt=""
                          />
                          {params.descrizione}
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {nuovoPreventivoForm.MittNazione === -1 ||
              nuovoPreventivoForm.MittNazione === "IT" ? (
                <Grid item xs={12} md={6} lg={6}>
                  <Autocomplete
                    fullWidth
                    value={nuovoPreventivoForm.MittLocalita || ""}
                    inputValue={nuovoPreventivoForm.MittLocalita || ""}
                    size="small"
                    isOptionEqualToValue={(option, value) => option.provincia === value.provincia || option.localita === value.localita}
                    filterOptions={filterOptions}
                    id="select-from-provinces"
                    //groupBy={(option) => option.split(" - ")[1][0]}
                    options={provincesFrom?.map((item) => {
                      return `${item.cap} - ${item.localita}${
                        item.provincia ? ` - ${item.provincia}` : ""
                      }`;
                    })}
                    loading={loading}
                    onInputChange={(e, newValue) => {
                      changeMittValue(newValue);
                    }}
                    onChange={(e, newValue) => {
                      changeMittValue(newValue);
                    }}
                    disabled={loading}
                    //getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Cap - Località - Provincia "
                        sx={{ marginTop: 2 }}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : (
                                ""
                              )}
                              {params.InputProps.startAdornment}
                            </InputAdornment>
                          ),
                        }}
                        helperText={touched.Codice && errors.Codice}
                        error={Boolean(touched.Codice && errors.Codice)}cr
                      />
                    )}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} md={3} lg={3}>
                  <Autocomplete
                    freeSolo
                    fullWidth
                    value={nuovoPreventivoForm.MittLocalita || ""}
                    inputValue={nuovoPreventivoForm.MittLocalita || ""}
                    size="small"
                    isOptionEqualToValue={(option, value) => option.provincia === value.provincia || option.localita === value.localita}
                    filterOptions={filterOptions}
                    id="select-from-provinces"
                    //groupBy={(option) => option.split(" - ")[1][1]}
                    options={provincesFrom?.map((item) => {
                      return item.localita
                    })}
                    loading={loading}
                    onInputChange={(e, newValue) => {
                      changeHandler("MittLocalita", newValue);
                    }}
                    onChange={(e, newValue) => {
                      changeHandler("MittLocalita", newValue);
                    }}
                    disabled={loading}
                    //getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Località"
                        sx={{ marginTop: 2 }}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : (
                                ""
                              )}
                              {params.InputProps.startAdornment}
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              )}

              {nuovoPreventivoForm.MittNazione === -1 ||
              nuovoPreventivoForm.MittNazione === "IT" ? (
                <></>
              ) : (
                <Grid item xs={12} md={3} lg={3}>
                  <Autocomplete
                    freeSolo
                    fullWidth
                    value={nuovoPreventivoForm.MittCap || ""}
                    inputValue={nuovoPreventivoForm.MittCap || ""}
                    size="small"
                    isOptionEqualToValue={(option, value) => option === value}
                    filterOptions={filterOptions}
                    id="select-from-provinces"
                    options={R.uniq(
                      provincesFrom.map((item) => {
                        return item.cap;
                      })
                    )}
                    onInputChange={(e, newValue) => {
                      changeHandler("MittCap", newValue);
                    }}
                    disabled={loading}
                    onChange={(e, newValue) => changeHandler("MittCap", newValue)}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Cap"
                        sx={{ marginTop: 2 }}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : (
                                ""
                              )}
                              {params.InputProps.startAdornment}
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
                   {configPreventivo?.mittenteindicatori?.visible ? (
                        <Grid item xs={12} md={12} lg={12}>
                        <Card elevation={0} sx={{ background: (theme) => theme.palette.warning.main + "20" }}>
                            <CardHeader
                            subheader={
                              (configPreventivo?.destinatarioindicatori?.label) && (configPreventivo.destinatarioindicatori.label !== "")?
                                (configPreventivo.destinatarioindicatori.label):("Accessori mittente")}
                            subheaderTypographyProps={{
                                fontWeight: "bold",
                            }}
                            avatar={<DragIndicatorTwoTone color={"primary"} />}
                            />
                        </Card>
                        </Grid>
                    ) : (
                        <></>
                    )}
                    {/* checkboxes */}
                    {configPreventivo?.mittenteindicatori?.visible? (
                    indicatoriMitt.map((data, index) => {
                    return (
                        <Grid item xs={12} md={2} lg={2} key={index}>
                        <FormGroup>
                            <FormControlLabel
                            control={
                                <Checkbox
                                // disabled={readOnly}
                                
                                checked={checkedIndicatoriMittente?.includes(data.codice)}
                                onChange={(e) =>
                                    changeIndicatoriMittenteChecked(
                                    e.target.checked,
                                    data.codice
                                    )
                                  //   changeIndicatoriMittenteCheckedDescrizione(
                                  //     e.target.checked,
                                  //     data.descrizione
                                  //   )
                                  // }                                    
                                }
                                />
                            }
                            label={data.descrizione}
                            />
                        </FormGroup>
                        </Grid>
                    );
                    })
                ) : (
                    <></>
                )}
                    


              
            </Grid>
          </CardContent>
        </Card>

        <Card elevation={0} sx={{ background: (theme) => theme.palette.success.light + "25" }}>
          <CardHeader 
            title={"Destinatario"}
            subheaderTypographyProps={{
              fontWeight: "bold",
            }}
            titleTypographyProps={{
              fontWeight: "bold",
              fontSize: 18,
            }}
            subheader={"Dati del Destinatario"}
            avatar={<PersonPinOutlined />}

          />
          <CardContent>
            <Grid item container spacing={1}>
          
              <Grid item xs={12} md={6} lg={6} mt={2}>
                <FormControl fullWidth>
                  <InputLabel id="select-from-nations">Nazione</InputLabel>
                  <Select
                    fullWidth
                    disabled={loading}
                    size="small"
                    labelId="select-from-nations"
                    id="select-from-nations"
                    value={nuovoPreventivoForm.DestNazione || ""}
                    label="Nazione"
                    onChange={(e) => changeHandler("DestNazione", e.target.value)}
                  >
                    <MenuItem value={-1}>SELEZIONA NAZIONE</MenuItem>
                    {nations?.map((params, key) => (
                      <MenuItem key={key} value={params.nazioneiso}>
                        <Box sx={{ "& > img": { mr: 2, flexShrink: 0 } }}>
                          
                          <img
                            loading="lazy"
                            width="20"
                            src={
                              params.nazioneiso?.toLowerCase() !== ""
                                ? `${getFlagPath(window.location.href, params.nazioneiso)}`
                                : ""
                            }
                            srcSet={
                              params.nazioneiso?.toLowerCase() !== ""
                                ? `${getFlagPath(window.location.href, params.nazioneiso)} 2x`
                                : ""
                            }
                            alt=""
                          />
                          {params.descrizione}
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {nuovoPreventivoForm.DestNazione === -1 ||
              nuovoPreventivoForm.DestNazione === "IT" ? (
                <Grid item xs={12} md={6} lg={6}>
                  <Autocomplete
                    fullWidth
                    value={nuovoPreventivoForm.DestLocalita || ""}
                    inputValue={nuovoPreventivoForm.DestLocalita || ""}
                    size="small"
                    isOptionEqualToValue={(option, value) => option.provincia === value.provincia || option.localita === value.localita}
                    filterOptions={filterOptions}
                    id="select-from-provinces"
                    //groupBy={(option) => option.split(" - ")[1][0]}
                    options={provincesFrom?.map((item) => {
                      return `${item.cap} - ${item.localita}${
                        item.provincia ? ` - ${item.provincia}` : ""
                      }`;
                    })}
                    loading={loading}
                    onInputChange={(e, newValue) => {
                      changeDestValue(newValue);
                    }}
                    onChange={(e, newValue) => {
                      changeDestValue(newValue);
                    }}
                    disabled={loading}
                    //getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Cap - Località - Provincia "
                        sx={{ marginTop: 2 }}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : (
                                ""
                              )}
                              {params.InputProps.startAdornment}
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} md={3} lg={3}>
                  <Autocomplete
                    freeSolo
                    fullWidth
                    value={nuovoPreventivoForm.DestLocalita || ""}
                    inputValue={nuovoPreventivoForm.DestLocalita || ""}
                    size="small"
                    isOptionEqualToValue={(option, value) => option.provincia === value.provincia || option.localita === value.localita}
                    filterOptions={filterOptions}
                    id="select-from-provinces"
                    //groupBy={(option) => option.split(" - ")[1][1]}
                    options={provincesFrom?.map((item) => {
                      return item.localita
                    })}
                    loading={loading}
                    onInputChange={(e, newValue) => {
                      changeHandler("DestLocalita", newValue);
                    }}
                    onChange={(e, newValue) => {
                      changeHandler("DestLocalita", newValue);
                    }}
                    disabled={loading}
                    //getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Località"
                        sx={{ marginTop: 2 }}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : (
                                ""
                              )}
                              {params.InputProps.startAdornment}
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              )}

              {nuovoPreventivoForm.DestNazione === -1 ||
              nuovoPreventivoForm.DestNazione === "IT" ? (
                <></>
              ) : (
                <Grid item xs={12} md={3} lg={3}>
                  <Autocomplete
                    freeSolo
                    fullWidth
                    value={nuovoPreventivoForm.DestCap || ""}
                    inputValue={nuovoPreventivoForm.DestCap || ""}
                    size="small"
                    isOptionEqualToValue={(option, value) => option === value}
                    filterOptions={filterOptions}
                    id="select-from-provinces"
                    options={R.uniq(
                      provincesFrom.map((item) => {
                        return item.cap;
                      })
                    )}
                    onInputChange={(e, newValue) => {
                      changeHandler("DestCap", newValue);
                    }}
                    disabled={loading}
                    onChange={(e, newValue) => changeHandler("DestCap", newValue)}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Cap"
                        sx={{ marginTop: 2 }}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : (
                                ""
                              )}
                              {params.InputProps.startAdornment}
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              )}

                {/* -----------------------------------------------------------------------------------------------------------------OCCHIO AL NOME MITTENTE.. DESTINATARIO?? */}

                {configPreventivo.destinatarioindicatori?.visible ? (
                    <Grid item xs={12} md={12} lg={12}>
                    <Card elevation={0} sx={{ background: (theme) => theme.palette.warning.main + "20" }}>
                        <CardHeader
                        subheader={
                          (configPreventivo?.destinatarioindicatori?.label) && (configPreventivo.destinatarioindicatori.label !== "")?
                            (configPreventivo.destinatarioindicatori.label):("Accessori destinatario")
                        } 
                        subheaderTypographyProps={{
                            fontWeight: "bold",
                        }}
                        avatar={<DragIndicatorTwoTone color={"primary"} />}
                        />
                    </Card>
                    </Grid>
                ) : (
                    <></>
                )}
                {/* -----------------------------------------------------------------------------------------------------------------OCCHIO AL NOME MITTENTE.. DESTINATARIO?? */}
                {configPreventivo.destinatarioindicatori?.visible? (
                    indicatoriDest.map((data, index) => {
                    return (
                        <Grid item xs={12} md={2} lg={2} key={index}>
                        <FormGroup>
                            <FormControlLabel
                            control={
                                <Checkbox
                                  //disabled={readOnly}
                                  checked={checkedIndicatoriDestinatario?.includes(data.codice)}
                                  onChange={(e) => 
                                      changeIndicatoriDestinatarioChecked(
                                        e.target.checked,
                                        data.codice,
                                      )
                                      // changeIndicatoriDestinatarioCheckedDescrizione(
                                      //   e.target.checked,
                                      //   data.descrizione,
                                      // )
                                    
                                  }
                                />
                            }
                            label={data.descrizione}
                            />
                        </FormGroup>
                        </Grid>
                    );
                    })
                ) : (
                    <></>
                )}
            </Grid>
          </CardContent>
        </Card>  
        <Grid container spacing={0} mt={2} mb={3}>
          <Grid item xs={12} md={12} lg={12}>

            <Card elevation={0} sx={{ background: (theme) => theme.palette.warning.main + "20" }}>
              <CardHeader
                title={"Data tassativa di consegna"}
                //subheader={subtitle !== "" ? (subtitle):("")}
                subheaderTypographyProps={{
                  fontWeight: "bold",
                }}
                titleTypographyProps={{
                  fontWeight: "bold",
                  fontSize:18
                }}
                avatar={<DateRangeRounded />}
              />
              <CardContent>
                <Grid item container spacing={1}>
                  <Grid item xs={12} md={12} lg={12}>
                      <Stack spacing={2} direction={"row"}>
                        <DatePicker
                          label={"Data"}
                          value={nuovoPreventivoForm.DataRichiesta || null}
                          onChange={(e, newValue) => {
                            changeHandler("DataRichiesta", moment(e).format("YYYY-MM-DD"));
                          }}
                          renderInput={(params) => <TextField {...params} size="small" />}
                        />
                      </Stack>
                    </Grid>
                </Grid>
              </CardContent>
            </Card>

            <Card elevation={0} sx={{ background: (theme) => theme.palette.warning.main + "20" }}>
              <CardHeader
                title={"Orario di consegna"}
                //subheader={subtitle !== "" ? (subtitle):("")}
                subheaderTypographyProps={{
                  fontWeight: "bold",
                }}
                titleTypographyProps={{
                  fontWeight: "bold",
                  fontSize:18
                }}
                avatar={<DateRangeRounded />}
              />
              <CardContent>
                <Grid item container spacing={1}>
                  <Grid item xs={12} md={12} lg={12}>
                      <Stack spacing={2} direction={"row"}>
                        <DesktopTimePicker
                          label={"Ora"}
                          value={nuovoPreventivoForm.OraRichiesta || null}
                          onChange={(e, newValue) => {
                            console.log('e', e);
                            console.log('newValue', newValue);
                            changeHandler("OraRichiesta", moment(e).format("hh:mm"));
                          }}
                          disableOpenPicker={true}
                          renderInput={(params) => <TextField {...params} size="small" />}
                        />
                      </Stack>
                    </Grid>
                </Grid>
              </CardContent>
            </Card>
  
        {configPreventivo?.dettagliomisuremerce?.visible ? (
          <DettaglioMisureMerce setPrevMainForm={setValues} prevMainForm={nuovoPreventivoForm} />
          ) : (
          <></>
        )}

<Card elevation={0} sx={{ background: (theme) => theme.palette.warning.main + "20" }}>
      <CardHeader
        title={"Richiesta di epal a rendere"}
        //subheader={subtitle !== "" ? (subtitle):("")}
        subheaderTypographyProps={{
          fontWeight: "bold",
        }}
        titleTypographyProps={{
          fontWeight: "bold",
          fontSize:18
        }}
        avatar={<QuestionAnswerTwoTone />}
      />
      <CardContent>
        <Grid item container spacing={1}>
          <Grid item xs={12} md={12} lg={12}>
            <TextField
              margin="normal"
              fullWidth
              size="small"
              label="Epal"
              type="number"
              autoComplete="off"
              onWheel={(e) => e.target.blur()}
              InputProps={{
                inputProps: { min: 0 }
              }}
              value={nuovoPreventivoForm.Epal || ""}
              onChange={(e) => {changeHandler("Epal", e.target.value)}}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>

        
        {configPreventivo?.contrassegno?.visible ?  (
          <></>
          ):(
          <Contrassegno setPrevMainForm={setValues} prevMainForm={nuovoPreventivoForm} />
        ) }

        {configPreventivo?.merceadr?.visible ? (
          <MerceADR setPrevMainForm={setValues} prevMainForm={nuovoPreventivoForm} />
          ) : (
          <></>
        )}
 
        {configPreventivo?.assicurazione?.visible ? (
          <Assicurazione setPrevMainForm={setValues} prevMainForm={nuovoPreventivoForm} />
          ) : (
          <></>
        )}
      </Grid>
    </Grid>
    <Stack direction={"row-reverse"} spacing={2} mt={2}>

      <Box sx={{position:'relative'}}>
        <Button
          color="success"
          size={"medium"}
          variant={"contained"}
          disabled={loadingPreventivi}
          onClick={() => postNuovoPreventivo(nuovoPreventivoForm/*, handleClose*/, navigate, setLoadingPreventivi)}
         >
            calcola preventivo
        </Button>
        {loadingPreventivi && (
          <CircularProgress
            size={24}
            sx={{
              color: green[500],
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
      </Box>
        

      {/* <Button
        color="warning"
        size={"medium"}
        variant={"contained"}
        onClick={() => resetPreventivoForm()}
      >
        SVUOTA CAMPI
      </Button> */}
    </Stack>
    </div>
  )
}

export default PreventiviNew